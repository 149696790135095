/* eslint-disable */

import React from 'react';
import { graphql, Link } from 'gatsby';

import Layout from '../components/Layout';

export default ({ data }) => {
  const post = data.markdownRemark;

  return (
    <Layout selectedMenuItem="blog">
      <Link to="../../blog">← back to blog</Link>
      <h1>{post.frontmatter.title}</h1>
      <div dangerouslySetInnerHTML={{ __html: post.html }} />
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
